.contentbox{
    width: 100%;
    margin-top: 20px;
}
.btnbox{
    width: 100%;
    border: 0px solid #000;
}
.txtcss{
    width: 100%;
    display: flex;
}
.hei10{
    height: 10px;
}
.hides{
    display: none;
}
.pllist{
    width: 100%;
    /* height: 100px; */
    border: 0px solid #000;
}
.plInput{
    width: 100%;
}