.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-title {
  padding-bottom: 10px;
  font-size: 20px;
}