* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

.ant-modal-footer {
  display: inherit;
}