.mprofile-container {
  border: 1px solid #eee;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}
.mprofile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profile-article {
  margin-top: 10px;
  display: flex;
  
  justify-content: space-between;
}

.profile-article .item {
  border-left: 1px solid #eee;
}

.profile-basic {
  margin-top: 10px;
}
.useimgs{
  width: 50px;
  height: 50px;
 
}
.ant-image{
  width: 50px;
  height: 50px;
 
}
.ant-upload-list{
  display: none;
}