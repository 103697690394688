.headerStyle {
  height: 64px;
  line-height: 64px;
  background-color: #fff;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--ant-font-family);
  font-size: var(--ant-font-size);
  flex-grow: 0;
  position: fixed;
  z-index: 100;
  width: 1200px;
  top: 0;
  
}
.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.navbar {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.navbar .item {
  width: 50px;
  text-align: center;
  cursor: pointer;
}
.nav-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  
}
.nav-profile .profile {
  cursor: pointer;
  text-align: center;
}

.active {
  box-sizing: border-box;
  color: #1677ff;
  border-bottom: 2px solid #1677ff;
}
.hides{
  display: none;
}


.logo {
  margin: 0;
  padding: 0;
}

.logo a {
  margin: 0 auto;
  padding: 0;
  height: 64px;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.88);
  font-weight: bold;
  font-family: AlibabaPuHuiTi, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo a svg {
  margin-right: 10px;
  font-size: 32px;
}

.logo .logoText {
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 20px;
  color: #ffffff;
}

.headbotton {
  color: rgba(255, 255, 255, 0.65);
  border-color: #d9d9d9;
  font-weight: 600;
  font-size: 14px;
  padding: 0 !important;
  width: auto;
  height: 64px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 6px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.headbotton:hover {
  color: rgba(255, 255, 255);
}



.footer {
  text-align: center;
}

.footer p {
  text-align: center;
  color: #939393;
}

.footer a:hover {
  color: #ffffff;
}

.footer a:hover p {
  color: #ffffff;
}

.footer a:hover li {
  box-shadow: 0 0px 0px red !important;
}

.footer .icp {
  margin-right: 10px;
  margin-bottom: 10px;
  color: #939393;
}

@media (min-width: 576px) {
  .logo {
      text-align: center;
  }
}