.article {
  margin-top: 30px;
  display: flex;
}

.list {
  flex: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.userInfo {
  margin-left: 10px;
  width: 300px;
  height: 100px;
}

.list-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.list-item {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
}

.list-item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item-right .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.icon-text {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

.icon-text .item {
  display: flex;
  gap: 5px;
}

.article-number {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.userInfo-article {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;

}

.record a{
  color: #000;
}

.record a:hover {
  color: #5f5d5d;
}